.navigetion {
    width: 90vw;
    display: flex;
    justify-content: space-between;

 }
 .logoinfo {
   display: flex;
   justify-content: center;
    background-color: #D7D3D3;

 }

 .primay {
    display: flex;

 }
   .contener {
      display: flex;
      flex-direction: column;
      background-color: rgb(233, 238, 225);
   }
