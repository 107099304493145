.workbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28pX;
    margin-top: 30px;
    width: 25vw;
    background-color:rgb(74 139 191) ;
    border-radius: 12px;
}

.workbox img {
    width: 16vw;
    height: 16vw;
}
 .workbox p {
    padding-top: 11px;
    font-size: 18px;
 }
@media (max-width:424px){

    .workbox {
        flex-direction:column;
        font-size: 10px;
        width: 80vw;
        
    }
    .cutsinfo{
        flex-wrap: wrap;
       
     }
     .workbox p {
        margin-right: 16px;
        font-size: 12px;
     }
     .footerInfo {
        flex-direction: column;
       
    }
    .footerItam {
        margin-top: 18px;
    }
    
    
    .workbox img {
        width: 41vw;
        height: 14vh;
    }
    

}
@media (min-width: 425px) and (max-width:767px) {

    .workbox {
        flex-direction:column;
        font-size: 10px;
        width: 80vw;
        
    }
    .workbox img {
        width: 31vw;
        height: 14vh;
    }
    .cutsinfo{
        flex-wrap: wrap;
       
     }
     .workbox p {
        margin-right: 16px;
        font-size: 12px;
     }
   
}
@media (min-width:768px) and (max-width: 1023px){

    .workbox {
        flex-direction:column;
        font-size: 10px;
        width: 80vw;
        
    }
    .cutsinfo{
        flex-wrap: wrap;
       
     }
     .workbox p {
        margin-right: 18px;
        font-size: 14px;
     }
     .footerInfo {
        flex-direction: column;
       
    }
    .footerItam {
        margin-top: 5px;
    }
    .workbox img {
        width: 21vw;
        height: 20vw;
    }
    
}
@media (max-width: 1200px) and (min-width: 1022px){
    .workbox p {
        font-size: 16px;
    }
    
}