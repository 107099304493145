.menu-box {
    display: flex;
    justify-content: center;
}

.new_backstatic {
    background-color: #2679bb;
}

.menu-list {
    width: 90vw;
    height: 6vh;
    align-items: center;
    display: flex;
    justify-content: space-around;
    background: #2679bb;
    border-top-left-radius: 82px;
    border-bottom-right-radius: 82px;
    font-size: 20px;

}

.link {
    text-decoration: none;
}

.menu-info a {
    text-decoration: none;
    color: black;

}

.menu-info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6vh;
}

.menu-info.active {
    background-color: #a9acad;
}

.menu-info:hover {
    background-color: #a9acad;
}

@media (max-width: 768px) {
  .menu-list {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .menu-list.active {
    display: flex;
  }

  .burger-menu {
    display: flex;
  }
}
