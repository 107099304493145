 

 .custbox {
    display: flex;
    justify-content: center;
    padding-top: 35px;
 }

 .custboxTitle {
    margin-bottom: 45px;
    
 }

 h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
    text-align: center;
    font-size: 35px;
 }
 .cutsinfo {
    width: 90vw;
    display: flex;
    justify-content: space-around;
 } 
 @media (max-width:424px){
 .cutsinfo {
   width: 100vw;
      }
      .custbox {
         padding-top: 0px;
     }
      

   }

   @media (max-width: 768px) and (min-width: 425px) {
      .custbox {
         padding-top: 0px;
     }
      

   }