.footer {
    height: 25vh;
    display: flex;
    justify-content: center;
    background-color: #4d7595;
}
.footerInfo {
    width: 82vw;
    display: flex;
    align-items: center;
    justify-content: center;


}
.footerItam {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 50px;

}
