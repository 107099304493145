
  .contactbox {
    height: 58vh;
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.contactTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;

}
.contimg {
    width: 49vw;
    height: 27vh;
}


.contactItam {
    height: 13vh;
    width: 44vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color:rgb(74 139 191) ;
    margin-top: 35px;
}
@media (max-width:424px){
    .contactbox {
        height: 45vh;
    }
    .contimg {
        width: 84vw;
        height: 12vh;
    }

    .contactItam {
        height: 10vh;
        width: 75vw;

}}
@media (max-width:768px) and (min-width: 425px){
    .contimg {
        width: 69vw;
        height: 12vh;
    }

    .contactItam {
        height: 9vh;
        width: 59vw;
    }
}
    @media (min-width:768px) and (max-width:1020px){
        .contimg {
            width: 65vw;
           height: 20vh;
        }
        .contactItam {
            height: 12vh;
            width: 54vw;
        }
    }

 @media (max-width: 1200px) and (min-width: 1022px){
    .contimg {
        width: 64vw;
       height: 26vh;
    }
    .contactItam {
        height: 12vh;
        width: 54vw;
    }
 }
