.museum {
    background-color: rgb(233, 238, 225);
}
.infobox {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}
.infoboxTitle {
    width: 85vw;
}
