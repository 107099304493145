@media (max-width: 424px) {
    .logoinfo {
        width: 100vw;
    }

    .contener {
        width: 100vw;

    }

    .footer {
        width: 100vw;
    }

    .navigetion {
        width: 100vw;
    }

    .menu-list {
        height: 5vw;
        font-size: 12px;
    }

    .menu-info {
        height: 5vw;
    }

    .infoP {
        font-size: 13px;

    }

    .titlecust h1 {
        font-size: 17px;
    
    }

    .infonavigation {
        height: 3vw;
        margin-right: 7px;
        margin-bottom: 6px;
    }

    .primay {
        flex-direction: column;
        justify-content: center;
    }

    .footerInfo {
        flex-direction: column;

    }

    .footerItam {
        margin-top: 21px;
    }

   

    .infotext {
        justify-content: start !important;
    }
    .infobox {
        margin-top: -6px;
    }

}

@media (max-width: 768px) and (min-width: 425px) {
    .menu-list {
        height: 5vw;
        font-size: 12px;
    }

    .menu-info {
        height: 5vw;
    }

    .infoP {
        font-size: 13px;

    }

    .titlecust h1 {
        font-size: 20px;
        
    }

    .infonavigation {
        height: 4vw;
        margin-right: 7px;
    }

    .primay {
        flex-direction: column;
        justify-content: center;
    }

    .footerInfo {
        flex-direction: column;

    }

    .footerItam {
        margin-top: 14px;
    }
    .infobox {
        margin-top: -6px;
    }
}

@media (min-width: 768px) and (max-width: 1020px) {
    .menu-list {
        height: 5vw;
        font-size: 12px;
    }

    .workbox {
        font-size: 10px;
    }

    .menu-info {
        height: 5vw;
    }

    .infoP {
        font-size: 13px;

    }

    .infonavigation {
        height: 3vw;
        margin-right: 7px;
    }

    .primay {
        margin-top: 5px
    }

    .titlecust h1 {
        font-size: 22px;
    }

}

@media (max-width: 1200px) and (min-width: 1022px) {
    .infonavigation {
        height: 3vw;
        margin-right: 12px;
    }

    .infoP {
        font-size: 15px;

    }

    .primay {
        margin-top: 16px
    }

    .menu-list {
        height: 5vw;
        font-size: 15px;
    }

    .menu-info {
        height: 5vw;
    }

    .workbox p {
        font-size: 14px;
    }

    .box p {
        font-size: 15px;
    }

    .titlecust h1 {
        font-size: 25px;
    }
}


