
.infoImg {
    width: 78vw;
    display: flex;
    justify-content: center;
    margin-top: 25px;
}
.infoImg img {
    margin-left: 12px;
    width: 24vw;
    height: 38vh;
}
.box {
    padding: 40px;
    margin-top: 50px;
    background-color:rgb(74 139 191);
    border-radius: 35px;

}
.infoP{
    font-size: 18px;
}
 .box h3{
    font-size: 26px;
    padding-bottom: 6px;
 }
@media (max-width:424px){
    .infoImg {
        flex-direction: column;
       margin-top: 0px;
    }
    .box p {
        font-size: 10px;
    }
    .box h3{
        font-size: 16px;
     }
     .infoImg img {
        width: 56vw;
        height: 15vh;
        margin-top: 17px;
        margin-left: 24px;
    }
    .box {
        padding: 31px;
    }
    .boxsize{
        margin-top: -43px;
    }
    .infortext{
        width: 82vw;
    }
    .infovideo {
        justify-content: end !important;
    }
    .oboute {
        flex-direction: column;
    }
    .infortext p{
        font-size: 13px;
    }
    .infortext h2{
        font-size: 15px;
        padding-bottom: 8px;
    
    }
    video {
        width: 44vw;
        height: 35vh;
    }
     .manue {
        height: 96vw;}


}
@media (min-width: 425px) and (max-width:767px) {

    .infoImg {
        flex-direction: column;
        margin-top: 0;
    }
    .box p {
        font-size: 12px;
    }
    .boxsize{
        margin-top: -43px;
    }
    .box h3{
        font-size: 18px;
     }
     .infoImg img {
        width: 53vw;
        height: 20vh;
        margin-top: 17px;
        margin-left: 35px;
    }
    .infortext {
        width: 72vw;
    }
    .oboute {
        flex-direction: column;
    }
    .infortext p{
        font-size: 13px;
    }
    .infortext h2{
        font-size: 17px;
        padding-bottom: 3px;
    }
    video {
        width: 44vw;
        height: 35vh;
    }
     .manue {
        height: 76vw;}

}
@media (min-width:768px) and (max-width: 1023px){

    .infoImg img {
        width: 20vw;
        height: 22vh;
    }
    .box p {
        font-size: 13px;
    }
    .box h3{
        font-size: 20px;
     }
     .boxsize{
        margin-top: -43px;
    }
     .infortext {
        width: 75vw;
    }
    .oboute {
        flex-direction: column;
    }
}
@media (min-width:1024px) and (max-width: 1200px){
    .infoImg img {
        width: 25vw;
        height: 30vh;
    }
    .box h3{
        font-size: 24px;
     }
     .box p {
        font-size: 16px;
    }

}
