.oboute {
    width: 82vw;
    padding: 23px;
    display: flex;
    justify-content: space-around;
    
 
}
.infortext {
    width: 48vw;
}

.infortext p {
    text-align: justify;
    line-height: 27px;
    font-style: italic;
}

.infortext h2 {
    text-align: center;
    padding-bottom: 7px;
} 
.infortext span {
    font-size: 23px;
}
.infovideo{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 13px;
    padding-left: 22px;
}
video {
    width: 25vw;
    height: 30vh;
}