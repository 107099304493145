


 .homeImage {
   height: 50vw;
   background-image: url(../../public/img/photo_2024-07-01_22-37-59.jpg);
   background-size: 100% 100%;
   background-repeat: no-repeat;
 }

 .info{
   background-color: rgb(233, 238, 225);
 }
 .infotext {
    display: flex;
    justify-content: center;
}
