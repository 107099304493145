.infonavigation {
    display: flex;
    margin-right: 28px;
    align-items: center;
    
}

.infonavigation p {
    padding:0 0 2px 5px;
    font-size: 12px;
}
.infonavigation img {
    width: 2vw;
    height: 2vw;
  
}
@media(max-width: 425px){
    .infonavigation {
        margin-right: -4px;
    }}
@media (max-width: 768px) and (min-width: 425px){
    .infonavigation {
        margin-right: -4px;
    }
}