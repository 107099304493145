/*NavBar.css*/

nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: rgb(233, 238, 225);
}

.NavLinks {
    height: 15vh;
}

nav ul {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

nav ul li {
    list-style-type: none;
    display: inline-block;
    padding: 10px 20px;
}

nav ul li a {
    text-decoration: none;
    font-weight: bold;
    color: black;
}

nav ul li a:hover {
    color: white;
    transition: .3s;
}

.MobileNavigation {
    display: none;
}

@media (max-width: 850px) {
    .DesktopNavigation {
        display: none;
    }

    .MobileNavigation {
        display: flex;
        align-items: center;
        height: 7vh;
    }

    .MobileNavigation .menu-list {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        position: absolute;
        top: 8vh;
        right: 0;
        height: 50vh;
        width: 100%;
        background: #2679bb;
    }

    .MobileNavigation .menu-info {
        width: 90%;
        border-bottom: 1px solid;
    }
}
